import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Emails } from "../../interface/emails";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import ItemHistory from "../ItemHistory/ItemHistory";
import "./emails.scss";
import { addEmail, updateEmail } from "../../services/emails";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import ReactQuill, { Quill } from 'react-quill';
import { RTEModules, RTEformats } from "../Shared/RTEFields";

const EditEmail = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<Emails>({
    defaultValues: state,
  });

  const onSubmit: SubmitHandler<Emails> = async (email) => {
    const resp = email.id ? await updateEmail(email) : await addEmail(email);
    resp?.status === 200
      ? navigate("/admin/emails")
      : alert("Something went wrong. Item not added.");
  };

  return (
    <div className="editFaqs max-width w-100 me-auto ms-auto mt-5">
      <Breadcrumb
        label={"Email Templates"}
        path={"admin/emails"}
        label2={"Add/Edit Email Templates"}
      />

      <h1 className="me-5">{state.id ? "Edit " : "New "} Email Template</h1>
      {state.id && <ItemHistory table_id={"emails"} item_id={state.id} />}
      <div className="registerForm d-block w-100">
        <form className="form d-block">
          <div className="body row">
            <span className="divider">Email Type</span>
            <hr />
            <div className="row">
              <div className="col-lg-4 col-12">
                <label>Email Type</label>
                <br />
                <input
                  style={{ width: "100%" }}
                  {...register("email_type", { required: true })}
                />
              </div>
            </div>
            <span className="divider">Email Subject</span>
            <hr />
            <div className="row">
              <div className="col-lg-6 col-18">
                <label>Subject</label>
                <br />
                <input
                  className=""
                  {...register("subject", { required: true })}
                />
              </div>
              <div className="col-lg-6 col-18">
                <label>Spanish Subject</label>
                <br />
                <input
                  className=""
                  {...register("subject_es", { required: true })}
                />
              </div>
            </div>
            <span className="divider">Email Body</span>
            <hr />
            <div className="row">
              <div className="col-12">
                <label>Email Body</label>
                <br />
                <Controller
                  control={control}
                  name='content'
                  rules={{ required: false }}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <ReactQuill
                      theme='snow'
                      onChange={onChange}
                      modules={RTEModules}
                      formats={RTEformats}
                      value={value || ""}
                    />)}
                />
              </div>
              <div className="col-12 mt-4">
                <label>Spanish Email Body</label>
                <br />
                <Controller
                  control={control}
                  name='content_es'
                  rules={{ required: false }}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <ReactQuill
                      theme='snow'
                      onChange={onChange}
                      modules={RTEModules}
                      formats={RTEformats}
                      value={value || ""}
                    />)}
                />
              </div>
            </div>
          </div>
          <div className="submit">
            <div className="max-width w-100 me-auto ms-auto d-flex">
              <button className="secondary" onClick={handleSubmit(onSubmit)}>
                Save & Close
              </button>
              <button
                className="ms-auto"
                onClick={() => {
                  navigate("/admin/emails");
                }}
              >
                Close
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditEmail;
