import React, { useState, useEffect } from "react";
import useSWR from "swr";
import { deleteMultipleEmail, getEmailTemplates } from "../../services/emails";
import { useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";
import { csvheaders, tableColumns } from "./metaData";
import EmailsTable from "./EmailsTable";
import "./emails.scss";
import Breadcrumb from "../Breadcrumb/Breadcrumb";

const Emails = () => {
  const { data, mutate } = useSWR("getEmails", getEmailTemplates);
  const [selectedArray, setSelectedArray] = useState<any[]>([]);
  const navigate = useNavigate();
  const tableData = {
    columns: tableColumns,
    rows: data,
  };

  const handleSelectedDelete = () => {
    try {
      if (
        window.confirm(
          "Are you sure you want to delete " + selectedArray.length + " items?"
        )
      ) {
        deleteMultipleEmail(selectedArray, mutate);
      }
    } catch (e) {
      alert(e);
    }
  };

  const handleIndividualDelete = (obj: any) => {
    try {
      if (window.confirm("Are you sure you want to delete this item?")) {
        deleteMultipleEmail([obj], mutate);
      }
    } catch (e) {
      alert(e);
    }
  };

  const handleNewClick = () => {
    return navigate("/admin/emails/edit", { state: {} });
  };
  const handleEditClick = (obj: any) => {
    return navigate("/admin/emails/edit", { state: obj });
  };

  return (
    <div className="max-width w-100 me-auto ms-auto mt-5">
      <Breadcrumb
        label={"Email Templates"}
        path={"admin"}
      />
      <div className="d-flex align-items-baseline">
        <h1 className="me-5">Email Templates</h1>
        <div className="d-flex ms-auto">
          <button className="secondary me-2" onClick={handleNewClick}>
            New
          </button>
          <button className="me-5 deleteButton" onClick={handleSelectedDelete}>
            Delete
          </button>
          {selectedArray.length > 0 ? (
            <CSVLink
              className="reportButton secondary me-2"
              data={selectedArray}
              headers={csvheaders}
              filename={"CEP-Emails.csv"}
            >
              Create a Report
            </CSVLink>
          ) : (
            <button className=" reportButton secondary disabled me-2" disabled>
              Create a Report
            </button>
          )}
        </div>
      </div>
      <div className="table-wrapper">
        <div className="table-wrapper">
          {data && (
            <EmailsTable
              data={tableData}
              selectedArray={selectedArray}
              setSelectedArray={setSelectedArray}
              handleEditClick={handleEditClick}
              handleIndividualDelete={handleIndividualDelete}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Emails;
