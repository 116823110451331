import "./adminTable.scss";
import Pagination from "react-bootstrap/Pagination";

const TablePagination = (props: any) => {
    return (
        <Pagination className="justify-content-end">
            <Pagination.First onClick={() => { props.setCurrentPage(1) }} />
            <Pagination.Prev onClick={() => { if (props.currentPage > 1) { props.setCurrentPage(props.currentPage - 1) } }} />

            {props.numPages < 10 ?
                [...Array(props.numPages)].map((x, i) =>
                    <Pagination.Item active={(i + 1) === props.currentPage} onClick={() => { props.setCurrentPage(i + 1) }}>{i + 1}</Pagination.Item>
                ) :
                <>
                    <Pagination.Item active={1 === props.currentPage} onClick={() => { props.setCurrentPage(1) }}>1</Pagination.Item>
                    <Pagination.Item active={2 === props.currentPage} onClick={() => { props.setCurrentPage(2) }}>2</Pagination.Item>
                    <Pagination.Item active={3 === props.currentPage} onClick={() => { props.setCurrentPage(3) }}>3</Pagination.Item>
                    {props.currentPage === 3 &&
                        <Pagination.Item active={4 === props.currentPage} onClick={() => { props.setCurrentPage(4) }}>4</Pagination.Item>
                    }
                    {props.currentPage === 4 &&
                        <>
                            <Pagination.Item active={4 === props.currentPage} onClick={() => { props.setCurrentPage(4) }}>4</Pagination.Item>
                            <Pagination.Item active={5 === props.currentPage} onClick={() => { props.setCurrentPage(5) }}>5</Pagination.Item>
                        </>
                    }
                    {props.currentPage > 4 && props.currentPage < props.numPages-2 &&
                        <>
                            <Pagination.Ellipsis />
                            <Pagination.Item active={false} onClick={() => { props.setCurrentPage(props.currentPage - 1) }}>{props.currentPage - 1}</Pagination.Item>
                            <Pagination.Item active={true} onClick={() => { props.setCurrentPage(props.currentPage) }}>{props.currentPage}</Pagination.Item>
                            <Pagination.Item active={false} onClick={() => { props.setCurrentPage(props.currentPage + 1) }}>{props.currentPage + 1}</Pagination.Item>
                        </>
                    }

                    <Pagination.Ellipsis />
                    {props.currentPage >= props.numPages-2 &&
                        <Pagination.Item active={props.numPages - 3 === props.currentPage} onClick={() => { props.setCurrentPage(props.numPages - 3) }}>{props.numPages - 3}</Pagination.Item>
                    }
                    <Pagination.Item active={props.numPages - 2 === props.currentPage} onClick={() => { props.setCurrentPage(props.numPages - 2) }}>{props.numPages - 2}</Pagination.Item>
                    <Pagination.Item active={props.numPages - 1 === props.currentPage} onClick={() => { props.setCurrentPage(props.numPages - 1) }}>{props.numPages - 1}</Pagination.Item>
                    <Pagination.Item active={props.numPages === props.currentPage} onClick={() => { props.setCurrentPage(props.numPages) }}>{props.numPages}</Pagination.Item>
                </>
            }


            <Pagination.Next onClick={() => { if (props.currentPage < props.numPages) { props.setCurrentPage(props.currentPage + 1) } }} />
            <Pagination.Last onClick={() => { props.setCurrentPage(props.numPages) }} />
        </Pagination>
    );
};

export default TablePagination;
