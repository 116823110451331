import React, { createContext, useContext } from "react";
import "./App.scss";
import { useMemo, useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { checkToken, setToken, checkAuthenticated } from "./services/auth";
import { UserContext } from "./context/UserContext";
import Login from "./components/Login/Login";
import Todo from "./components/Faq/Faq";
import NotFound from "./components/NotFound/NotFound";
import NormalNavbar from "./components/Navigation/Navbar";
import "bootstrap/dist/css/bootstrap.min.css";
import AdminNavbar from "./components/Navigation/AdminNavbar";
import EditTodo from "./components/Faq/EditTodo";
import Register from "./components/Register/Register";
import Admin from "./components/Admin/Admin";
import Registrants from "./components/Registrants/Registrants";
import EditRegistrant from "./components/Registrants/EditRegistrant";
import EditFaq from "./components/Faqs/EditFaq";
import Faq from "./components/Faqs/Faqs";
import EditCompany from "./components/Company/EditCompany";
import Emails from "./components/Emails/Emails";
import Company from "./components/Company/Company";
import ContentBlocks from "./components/ContentBlocks/ContentBlocks";
import HomeMember from "./components/HomeMember/HomeMember";
import EditContentBlocks from "./components/ContentBlocks/EditContentBlocks";
import ContentBlocksAdmin from "./components/ContentBlocks/ContentBlocksAdmin";
import { Buffer } from "buffer";
import { Helmet } from "react-helmet"
import EditEmail from "./components/Emails/EditEmail";
import RegisterMember from "./components/RegisterMember/RegisterMember";

window.Buffer = Buffer;

checkAuthenticated();
function App() {

  const [user, setUser] = useState(checkToken());
  const value = useMemo(() => ({ user, setUser }), [user, setUser]);

  useEffect(() => {
    setToken(setUser);
  }, []);
  return (
    <>
      <Helmet>
        <meta http-equiv='cache-control' content='no-cache' />
        <meta http-equiv='expires' content='0' />
        <title>Reliant - Client Member Program</title>
      </Helmet>
      <UserContext.Provider value={[value.user]}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<NormalNavbar />}>
              <Route index element={<HomeMember />} />
              <Route path='/login' element={<Login />} />
              <Route path='/register' element={<Register />} />
              <Route path='/registermember' element={<RegisterMember />} />
            </Route>
            <Route path="/:locale" element={<NormalNavbar />}>
              <Route index element={<HomeMember />} />
              <Route path='/:locale/login' element={<Login />} />
              <Route path='/:locale/register' element={<Register />} />
            </Route>
            {/* admin */}
            {user && (
              <Route path="/admin" element={<AdminNavbar />}>
                  <Route path="" element={<Admin />} />
                  <Route path="registrants" element={<Registrants />} />
                  <Route path="registrants/edit" element={<EditRegistrant />} />
                  <Route path="faqs" element={<Faq />} />
                  <Route path="emails" element={<Emails />} />
                  <Route path="emails/edit" element={<EditEmail />} />
                  <Route path="faqs/edit" element={<EditFaq />} />
                  <Route path="companies" element={<Company />} />
                  <Route path="companies/edit" element={<EditCompany />} />
                  <Route path="content-blocks" element={<ContentBlocksAdmin />} />
                  <Route path="content-blocks/edit" element={<EditContentBlocks />} />
              </Route>
          )}
          {user && (
              <Route path="/" element={<AdminNavbar />}>
                  <Route path="/todo" element={<Todo />} />
                  <Route path="/edittodo/:id" element={<EditTodo />} />
              </Route>
          )}
          <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </UserContext.Provider>
    </>
  );
}

export default App;
