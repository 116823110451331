import React, { useState } from 'react'
import AddTodo from './AddFaq'
import useSWR from "swr";
import "./Faq.scss";
import AddFaq from './AddFaq';
import { getFaqs } from '../../services/faq';
import ListFaq from './ListFaq';

const Faq = () => {
  const { data, error, isLoading } = useSWR(
    "listFaqs",
    getFaqs,
    {
      revalidateOnFocus: false
      , revalidateOnReconnect: false,
      revalidateIfStale: false,
    }
  );

  return (
    <>
      <div className='faq'>
        <AddFaq data={data} />
        <ListFaq data={data} error={error} isLoading={isLoading} />
      </div>
    </>
  )
}

export default Faq;