import axios, { AxiosError, AxiosRequestConfig, AxiosResponse, AxiosInstance } from "axios";
let url =  'http://localhost:5000'; 
if (window.location.host === 'cmp.myreliant.com') {
   url = 'https://backend-cmp.myreliant.com'
}
if (window.location.host === 'projecta-goku.myreliant.com') {
   url = 'https://project.freeza.nrg.com/'
} 



// Root url
axios.defaults.baseURL = url;

// Handle 400 & 500 errors
axios.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => {
    const expectedError =
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500;
  
    if (!expectedError) {
      console.error(error);
    }
  
    return Promise.reject(error);
  }
);

// Request interceptors for API calls
axios.interceptors.request.use(
  (config: any) => {
    config.headers = config.headers || {};
    config.headers['token'] = `${sessionStorage.getItem('token')}`;
    return config;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  }
);

// Create an Axios instance with the interceptors
const api: AxiosInstance = axios.create();
api.interceptors.request.use(
  (config: any) => {
    config.headers = config.headers || {};
    config.headers['token'] = `${sessionStorage.getItem('token')}`;
    return config;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  }
);
api.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => {
    const expectedError =
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500;
  
    if (!expectedError) {
      console.error(error);
    }
  
    return Promise.reject(error);
  }
);

// Set X Auth in outbound headers
function setJwt(jwt: string): void {
  api.defaults.headers.common["token"] = jwt;
}

// Create an object with the Axios methods and the setJwt function
const http = {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  setJwt,
};

// Export the http object as the default export
export default http;