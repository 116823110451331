import React from "react";
 import { Link, useParams } from "react-router-dom";
 import { FiEdit3 } from "react-icons/fi";
 
 const NotCustomerMember = () => {
   const params = useParams();
   return (
    <div className="not-customer col-lg-6 col-12">
      <div className="home-block">
        <div>
          <h2>{params.locale === 'es' ? "¿No es cliente?" : "Not a Customer?"}</h2>
          <p>
          {params.locale === 'es' ? "Inscríbase en un plan residencial de electricidad y Facturación Electrónica de Reliant." :  "Sign up for a qualifying residential electrical plan."}  
          </p>
          <p>
          {params.locale === 'es' ? 'Una vez que complete su registro, debe volver a este sitio web e inscribirse en el programa en la sección "Ya soy cliente" utilizando su número de cuenta.' :  "Once you complete your sign-up, please return to this website and enroll in the program under the 'Already a Customer' section using your account number."}  
          </p>
        </div>
        <div className="submit">
          <a href="https://www.reliant.com/en/private/business/channel-partner/realtor/alliance-credit-union" target="_blank" rel="noreferrer">
            <button>
              {params.locale === 'es' ? "Inscribirse" : "Sign up"}
            </button>
          </a>
       </div>
     </div>
    </div>
   );
 };
 
 export default NotCustomerMember;