import React, { useState, useEffect } from "react";
import useSWR from "swr";
import { FiArrowRight } from "react-icons/fi";
import { useForm, SubmitHandler } from "react-hook-form";
import { LoginUser } from "../../interface/admin";
import { LoginCompany } from "../../services/Company";
import { useNavigate, useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

const CustomerMember = () => {
	const [hide, setHide] = useState(false);
	const [errorText, setErrorText] = useState(false);
	const navigate = useNavigate();
	const params = useParams();
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<LoginUser>();

	const onSubmit: SubmitHandler<LoginUser> = async (formData) => {
		let resp: any = await LoginCompany(formData);
		// If you are on spanish route, redirect to spanish register, otherwise english site
		if (resp.status === 401) {
			setErrorText(true);
			setTimeout(() => setErrorText(false), 2000); //resets error text
		} else {
			navigate(
				params.locale === "es" ? "/es/registermember" : "/registermember",
				{ state: resp.data.company }
			);
		}
		return;
	};

	return (
		<div className='col-lg-6 col-12'>
			{/** Customer CTA */}
			<div className={"customer " + (hide ? "d-none" : "d-block")}>
				<div className='home-block'>
					<div>
						<h2>
							{params.locale === "es" ? "¿Ya es cliente?" : "Already a Customer?"}
						</h2>
						<p>
							{params.locale !== "es" && "Register to access your exclusive savings with the Client Member Program. You'll need your Reliant account number, unique company code and ESI ID number.​"}
						</p>
					</div>
					<div className='submit'>
						<button
							onClick={(event: React.MouseEvent<HTMLElement>) => {
								setHide(true);
							}}
						>
							{params.locale === "es" ? "Continuar" : "Register"}
						</button>
					</div>
				</div>
			</div>

			{/** Login Form */}
			<div
				className={"login noBorder " + (!hide ? "d-none" : "d-block customer")}
			>
				<div className="home-block">
					<h2 className='reliant-pink'>
						{" "}
						{params.locale === "es"
							? "Comenzar Es Fácil"
							: "Getting Started is Easy"}
					</h2>
					<p>
						{params.locale === "es"
							? "introduzca su código de empresa"
							: " Enter your company code."}
					</p>

					<form onSubmit={handleSubmit(onSubmit)}>
						<div className='mb-5'>
							<label htmlFor='password'>
								{" "}
								{params.locale === "es"
									? "Codigo de compañia:"
									: "Company Code:"}{" "}
							</label>
							<input
								type='password'
								id='password'
								{...register("password", { required: true })}
							/>
						</div>
						<div className='submit mt-3'>
							<input
								type='submit'
								value={params.locale === "es" ? "Entregar" : "Submit"}
							/>
						</div>
					</form>
				</div>
				<p className="consent">
					By clicking 'submit', I authorize Reliant to share my enrollment information with the company participating in the Client Member Program for purposes of validating my enrollment.
				</p>
				<p className={"errorText " + (errorText ? "d-block" : "d-none")}>
					*
					{params.locale === "es"
						? "Nombre de empresa y contraseña incorrectos"
						: "Wrong Company name and password"}
					*
				</p>
			</div>
		</div>
	);
};

export default CustomerMember;
