import React from "react";
//  import "./Home.scss";
import { useParams } from "react-router-dom";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
 
 const TermsMember = () => {
   const params = useParams();
 
   return (
    <>
     <div className="terms">
        <Accordion
          sx={{
            boxShadow: "none",
          }}
        >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          style={{ justifyContent: "left" }}
        >
        <Typography>
          {params.locale === "es" ? (
            <p>Términos & condiciones</p>
          ) : (
            <Typography>Terms & Conditions</Typography>
          )}
        </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {params.locale === "es" ? (
              <>
                <p>
                  *El descuento de Facturación Electrónica será efectivo dentro de dos
                  ciclos de facturación después de la inscripción, registro en
                  Facturación Electrónica, y verificación de empleo y estatus de
                  cliente. Usted debe estar inscrito en Facturación Electrónica de
                  Reliant para recibir el descuento. El descuento en su factura estará
                  basado en el mes actual de consumo y no aplica a saldos vencidos,
                  impuestos, Reliant Home SolutionsSM, Reliant EcoShareSM, servicios de
                  terceros, cargos no recurrentes o luces de seguridad. El descuento de
                  Facturación Electrónica será aplicado a un solo medidor por empleado,
                  antes de que otros descuentos u opciones de facturación sean
                  calculados.
                </p>
                <p>
                **El Programa Reliant Cliente Empleado está disponible para empleados
                activos de compañías a las cuales Reliant proporciona servicio
                minorista de electricidad. Esta oferta no es transferible y solamente
                está disponible a clientes residenciales de Texas. Los clientes que se
                inscriban en el Reliant SmartStart PlanSM no son elegibles para el
                descuento por facturación electrónica. <br />
                <br /> Para recibir el descuento por Facturación Electrónica, usted
                debe ser un cliente de Reliant y su cuenta de electricidad de Reliant
                debe estar a su nombre o mostrar una dirección de servicio que
                corresponda a la dirección de su vivienda como se refleja en los
                registros de empleo de su compañía. El descuento por Facturación
                Electrónica termina inmediatamente si usted se cambia a una dirección
                de servicio diferente. Es necesario reinscribirse en la nueva
                dirección de servicio.
              </p>
              <p>
                Su participación en el Reliant Client Member Program y el Descuento
                por Facturación Electrónica terminarán inmediatamente con la
                terminación de su empleo con su compañía o con la terminación del
                contrato de electricidad de su compañía con Reliant. Reliant se
                reserva el derecho de descontinuar este programa sin aviso.
              </p>
              </>
            ) : (
              <>
                <p>
                  *Through the Reliant Client Member Program (the "Program"), you are eligible for a discount on the energy charge portion of your electricity bill as an active member of a company participating in the Program. The discount will become effective within two billing cycles following enrollment in the Program and applies to the current month's billed energy charges set forth on the plan's Electricity Facts Label. The discount does not apply to other charges, including base charges, usage fees, TDU delivery charges, taxes, third-party services, non-recurring charges, guard lights, prepaid electricity, non-commodity services like Reliant BuyBack Plan or equivalent, Make It Solar or Reliant EcoShareSM. The discount will be applied to a single meter per member before other discounts or billing options are calculated. The Program is available for active members of companies to which Reliant provides retail electricity service and that are participating in the Program. This offer is not transferrable and is only available to residential customers in Texas.
                </p> 
                <p>
                  To receive the discount, you must be a Reliant customer and a member of a company that is participating in the Program and your Reliant electricity account must be in your name and show a service address that matches the home address associated with your membership with the company participating in the Program. The discount ends immediately if you move to a different service address. Re-enrollment is required at the new service address.
                </p> 
                <p>
                  Your participation in the Program and the discount will end immediately upon termination of your membership with the company participating in the Program, or upon termination of that company's participation in the Program with Reliant. Reliant reserves the right to discontinue this program without notice. The discount cannot be combined with other loyalty discounts.
                </p>
              </>
            )}
          </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
     </>
   );
 };
 
 export default TermsMember;