// @ts-nocheck
import React, { useState, useEffect } from "react";
import useSWR from "swr";
import { useLocation } from "react-router-dom";
import { Company } from "../../interface/company";
import { updateCompany, addCompany, getAWSCreds, uploadCompanyImage, generateS3URL} from "../../services/Company";
import { useForm, SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import "./Company.scss";
import { uploadFile } from "react-s3";
import ItemHistory from "../ItemHistory/ItemHistory";
import Breadcrumb from "../Breadcrumb/Breadcrumb";

const EditCompany = () => {
  const { state } = useLocation();
  const { data, mutate } = useSWR("getAWSCreds", getAWSCreds);
  const [logoImage, setLogoImage] = useState(state.logo ? state.logo : "");
  const navigate = useNavigate();
  let AWSConfig = {};
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Company>({
    defaultValues: state,
  });

  const onSubmit: SubmitHandler<Company> = async (company) => {
    logoImage ? (company.logo = logoImage) : (company.logo = null);
    const resp = company.id
      ? await updateCompany(company)
      : await addCompany(company);
    resp?.status === 200
      ? navigate("/admin/companies")
      : alert("Something went wrong with submission");
    return;
  };

  const convertToBase64 = (file) => {
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
          resolve(reader.result);
      }
    })
  }
  const handleAttachments = async (e) => {
    const file = e.target.files[0];
    if (file.type.includes('png') || file.type.includes('gif')) {
      const convertedFile = await convertToBase64(file);
      const newFilename = Date.now() + file.name;
      const uploadedFile = await uploadCompanyImage(convertedFile, newFilename);
      const generatedUrl = `https://cepbucket.s3.amazonaws.com/${newFilename}`;
      setLogoImage(generatedUrl);
    }
    else {
      alert("Please upload a PNG or GIF image file.");
      e.target.value = null;
    }
  };

  return (
    <div className="editRegistrant max-width w-100 me-auto ms-auto mt-5">
      <Breadcrumb label={"Company"} path={"admin/companies"} label2={"Add/Edit Company"} />

      <h1 className="me-5">{state.id ? "Edit " : "New "} Company</h1>
      {state.id && <ItemHistory table_id={"companies"} item_id={state.id} />}
      <div className="registerForm d-block w-100">
        <form className="form d-block" onSubmit={handleSubmit(onSubmit)}>
          <div className="body row">
            <span className="divider">Basic Company Details</span>
            <hr />
            <div className="row">
              <div className="col-lg-4 col-12">
                <label>Company Name</label>

                <input type="text" {...register("name", { required: true })} />
                {errors.notes && <span>This field is required</span>}
              </div>
            </div>
            <div className="row">
              <div className="col-lg-5 col-12">
                <label>Logo</label>
                <input type="file" accept=".png, .gif" onChange={(e) => handleAttachments(e)} />
                <span className="d-block mt-1 ">
                  *Upload a company's logo into the system. -- The file will be
                  resized if necessary.
                </span>
                <span className="d-block mt-1">
                  {" "}
                  *You should upload a PNG or GIF image with the background
                  knocked out.
                </span>
                <span className="d-block mt-1">
                  {" "}
                  *The logo sits on an off-white, gradient background and may
                  look strange if color is left in the original background.
                </span>
              </div>
              <div className="col-lg-6 col-12 offset-lg-1">
                {logoImage && (
                  <img
                    className="logoImage"
                    src={logoImage}
                    alt="company logo"
                  />
                )}
              </div>
            </div>
            <span className="divider">Company Program Details</span>
            <hr />

            <div className="row">
              <div className="col-lg-4 col-12">
                <label>Discount</label>
                <select {...register("discount")}>
                  <option value="5%">5%</option>
                  <option value="10%">10%</option>
                </select>
              </div>
            </div>
            <div className="row"></div>

            <div className="row">
              <div className="col-lg-4 col-12">
                <label>Contract Expiration Date"</label>
                <input
                  type="date"
                  {...register("contract_expiration_date", { required: true })}
                />
              </div>
            </div>

            <div className="col-lg-4 col-12">
              <label>Status</label>
              <select {...register("status")}>
                <option value="Active">Active</option>
                <option value="Expired">Expired</option>
              </select>
            </div>
            <span className="divider">Final Details</span>
            <hr />
            <div className="row">
              <div className="col-lg-6 col-12">
                <label>Company Password</label>
                <input {...register("password", { required: true })} />
                <span className="d-block mt-0">
                  *The password to register with this company. Must be unique.
                </span>
              </div>
              <div className="row"></div>
              <div className="col-lg-5 col-10">
                <label>Notes</label>
                <textarea className="companyNotes"
                  type="textarea"
                  {...register("notes", { required: true })}
                />
              </div>
            </div>
          </div>

          <div className="submit">
            <div className="max-width w-100 me-auto ms-auto d-flex">
              <button className="secondary" onClick={handleSubmit(onSubmit)}>
                Save & Close
              </button>
              {/* <button className=" secondary ms-5" type='submit'>Save and Close</button> */}
              <button
                className="ms-auto"
                onClick={() => {
                  navigate("/admin/companies");
                }}
              >
                Close
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};


export default EditCompany;
